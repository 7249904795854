import React, { Component } from 'react'

export default class IconDownload extends Component {
	render() {
		return (
			<svg
				className="icon"
				width="24px"
				height="16px"
				viewBox="0 0 24 16"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g
					id="Button---Download-(Ford)"
					transform="translate(-21.000000, -5.000000)"
					fillRule="nonzero"
				>
					<g id="Download">
						<path
							fill="currentColor"
							d="M31.4443439,6.77777778 C33.3573344,6.77777778 34.9960389,7.93716162 35.6810206,9.5835404 C36.0811628,9.24520707 36.6003995,9.04040404 37.1683258,9.04040404 C38.4328249,9.04040404 39.4579186,10.0534242 39.4579186,11.3030303 C39.4579186,11.7212273 39.3426519,12.1126263 39.1424914,12.4487323 C39.2473584,12.4392087 39.3526097,12.4344074 39.4579186,12.4343434 C41.3546673,12.4343434 42.8923077,13.9538737 42.8923077,15.8282828 C42.8923077,17.7026919 41.3546673,19.2222222 39.4579186,19.2222222 L27.4375566,19.2222222 C25.2246652,19.2222222 23.4307692,17.4494545 23.4307692,15.2626263 C23.4307692,13.267697 24.9237268,11.6176414 26.8656592,11.3433687 C26.8655519,11.329899 26.8651584,11.3165 26.8651584,11.3030303 C26.8651584,8.80381818 28.9153456,6.77777778 31.4443439,6.77777778 Z M31.4365385,5 C28.3966931,5 25.8482572,7.13328571 25.2455135,9.99192857 C23.0950494,10.8617857 21.6615385,12.9434286 21.6615385,15.2857143 C21.6615385,18.4419286 24.2361728,21 27.4115385,21 L39.4865385,21 C42.345151,21 44.6615385,18.6968929 44.6615385,15.8571429 C44.6615385,13.6498214 43.2532197,11.72125 41.2017635,11.0038929 C41.0576541,8.93271429 39.322376,7.28571429 37.1865385,7.28571429 C36.9149228,7.28571429 36.6456431,7.31310714 36.3826525,7.3665 C35.2359588,5.93828571 33.4523088,5 31.4365385,5 Z M31.0110251,9.84955645 L31.0110251,14.557802 L29.1392057,12.675314 C28.9789111,12.5141132 28.7156999,12.5126953 28.5536208,12.6721406 L27.9744532,13.2418969 C27.8136438,13.4000931 27.8136438,13.6565965 27.9744532,13.8148265 L31.5434187,17.3257973 C31.704228,17.4839935 31.9649684,17.4839935 32.1258121,17.3257973 L35.6947776,13.8148265 C35.855587,13.6566303 35.855587,13.4001269 35.6947776,13.2418969 L35.11561,12.6721406 C34.9535309,12.5126953 34.6903197,12.5141132 34.5300251,12.675314 L32.65824,14.557802 L32.65824,9.84955645 C32.65824,9.62583335 32.4738549,9.44444444 32.2464363,9.44444444 L31.4228288,9.44444444 C31.1954102,9.44444444 31.0110251,9.62583335 31.0110251,9.84955645 Z"
							id="download"
						/>
					</g>
				</g>
			</svg>
		)
	}
}

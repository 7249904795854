export default function(blocks: any, params: any) {
	const { footer, content } = blocks
	const center = blocks.center || false
	const styles = {
		btnBgColor: params.settings.style_primary_action_background_color || '#236aea',
		btnTextColor: params.settings.style_primary_action_color || '#fff',
		primaryAltColor: params.settings.style_primary_action_background_color || '#236aea',
		whiteColor: '#ffffff',
		lightColor: '#f6f6f6',
		darkColor: '#999999',
	}
	return /*html*/ `
<!DOCTYPE html>
<html>
	<head>
		<meta name="viewport" content="width=device-width" />
		<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
		<style>
			/* -------------------------------------
			    INLINED WITH htmlemail.io/inline
			------------------------------------- */
			/* -------------------------------------
			    RESPONSIVE AND MOBILE FRIENDLY STYLES
			------------------------------------- */
			@media only screen and (max-width: 620px) {
			  table[class=body] h1 {
			    font-size: 28px !important;
			    margin-bottom: 10px !important;
			  }
			  table[class=body] p,
				table[class=body] ul,
				table[class=body] ol,
				table[class=body] td,
				table[class=body] span,
				table[class=body] a {
			    font-size: 16px !important;
			  }
			  table[class=body] .wrapper,
			        table[class=body] .article {
			    padding: 10px !important;
			  }
			  table[class=body] .content {
			    padding: 0 !important;
			  }
			  table[class=body] .container {
			    padding: 0 !important;
			    width: 100% !important;
			  }
			  table[class=body] .main {
			    border-left-width: 0 !important;
			    border-radius: 0 !important;
			    border-right-width: 0 !important;
			  }
			  table[class=body] .btn table {
			    width: 100% !important;
			  }
			  table[class=body] .btn a {
			    width: 100% !important;
			  }
			  table[class=body] .img-responsive {
			    height: auto !important;
			    max-width: 100% !important;
			    width: auto !important;
			  }
			}

			/* -------------------------------------
			    PRESERVE THESE STYLES IN THE HEAD
			------------------------------------- */
			@media all {
			  .ExternalClass {
			    width: 100%;
			  }
			  .ExternalClass,
				.ExternalClass p,
				.ExternalClass span,
				.ExternalClass font,
				.ExternalClass td,
				.ExternalClass div {
			    line-height: 100%;
			  }
			  .apple-link a {
			    color: inherit !important;
			    font-family: inherit !important;
			    font-size: inherit !important;
			    font-weight: inherit !important;
			    line-height: inherit !important;
			    text-decoration: none !important;
			  }
			  .btn-primary table td:hover {
			    background-color: ${styles.primaryAltColor} !important;
			  }
			  .btn-primary a:hover {
			    background-color: ${styles.primaryAltColor} !important;
			    border-color: ${styles.primaryAltColor} !important;
			  }
			}
		</style>
	</head>
	<body
		class=""
		style="background-color: ${
			styles.lightColor
		}; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"
	>
		<table
			border="0"
			cellpadding="0"
			cellspacing="0"
			class="body"
			style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background-color: ${
				styles.lightColor
			};"
		>
			<tr>
				<td style="font-family: sans-serif; font-size: 14px; vertical-align: top;">&nbsp;</td>
				<td
					class="container"
					style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; Margin: 0 auto; max-width: 580px; padding: 10px; width: 580px;"
				>
					<div
						class="content"
						style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 580px; padding: 10px;"
					>
						<!-- START CENTERED WHITE CONTAINER -->
						<span
							class="preheader"
							style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;"
							>This is preheader text. Some clients will show this text as a preview.</span
						>
						<table
							class="main"
							style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background: ${
								styles.whiteColor
							}; border-radius: 3px;"
						>
							<!-- START MAIN CONTENT AREA -->
							<tr>
								<td
									class="wrapper"
									style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;"
								>
									<table
										border="0"
										cellpadding="0"
										cellspacing="0"
										style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;"
									>
										<tr>
											<td style="font-family: sans-serif; font-size: 14px; vertical-align: top; text-align: ${
												center ? 'center' : 'left'
											}">
												${content}
											</td>
										</tr>
									</table>
								</td>
							</tr>

							<!-- END MAIN CONTENT AREA -->
						</table>

						<!-- START FOOTER -->
						<div
							class="footer"
							style="clear: both; Margin-top: 10px; text-align: center; width: 100%;"
						>
							<table
								border="0"
								cellpadding="0"
								cellspacing="0"
								style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;"
							>
								<tr>
									<td
										class="content-block"
										style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; font-size: 12px; color: ${
											styles.darkColor
										}; text-align: center;"
									>
										${footer}
									</td>
								</tr>
							</table>
						</div>
						<!-- END FOOTER -->

						<!-- END CENTERED WHITE CONTAINER -->
					</div>
				</td>
				<td style="font-family: sans-serif; font-size: 14px; vertical-align: top;">&nbsp;</td>
			</tr>
		</table>
	</body>
</html>

`
}
